<template>
    <base-table :data="taskList" thead-classes="text-primary">
      <template slot-scope="{row}">
        <td>
           <span class="tale-status-wait" v-if="row.type == 'error'"> {{row.type}} </span>
           <span class="tale-status-active" v-if="row.type == 'info'"> {{row.type}} </span>

        </td>
        <td>
          <p class="title">{{row.title}}</p>
          <p class="text-muted">{{row.description}}</p>
        </td>
        <td class="td-actions text-right">
          <base-button type="link" aria-label="edit button" @click="openInfo(row.data)">
            <i class="tim-icons icon-paper"></i>
          </base-button>
        </td>
      </template>
    </base-table>
</template>
<script>
  import {BaseTable} from '@/components'
  export default {
    components: {
      BaseTable
    },
    props: {
      taskList: {
        type: Array,
        default: () => []
      },
    },
    methods: {
      openInfo(data){
          this.$emit("infolog-modals",data);
      }

    },
    computed: {

    }
  }
</script>
<style>
</style>
