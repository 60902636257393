<template>
<div class="box-login">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
   <!-- :can-cancel="true" :on-cancel="onCancel" -->
  <!-- <h3 slot="header" class="title text-center">เข้าสู่ระบบ</h3> --> 
  <base-alert v-if="isAlert.open" type="danger" v-on:click="isAlert.open == false">
    <strong> {{isAlert.tip}} </strong> {{isAlert.msg}}
    <button type="button" aria-label="Close" class="close" @click="isAlert.open = false"><span aria-hidden="true"><i class="tim-icons icon-simple-remove"></i></span></button>
  </base-alert>
  <card class="mt-5" style="background: transparent;">
    <form class="login-form">
      <div class="text-center mb-4"><img :src="logo" style="max-width: 200px; width: 100%;" /></div>
      <!-- <label for="user" class="mb-3">ยูเซอร์</label> -->
      <div class="form-group">
        <input type="text" v-model="model.username" autocomplete="off"  placeholder="Username" class="form-login-control">
      </div>
      
      <!-- <base-input id="user" v-model="model.username" autocomplete="off" type="text" addon-left-icon="tim-icons icon-single-02" placeholder="Username"></base-input> -->
      <!-- <label for="pass" class="mb-3">รหัสผ่าน</label> -->
      <!-- <base-input id="pass" v-model="model.password" autocomplete="off" type="password" addon-left-icon="tim-icons icon-lock-circle" placeholder="Password"></base-input> -->
      <div class="form-group">
        <input type="password" v-model="model.password" autocomplete="off"  placeholder="Password" class="form-login-control">
      </div> 
      <div class="boxAct" v-show="disbox">
        <label for="user" class="mb-3">Agent Key</label>
        <base-input id="user" v-model="model.agentid" autocomplete="off" type="text" addon-left-icon="tim-icons icon-key-25" placeholder="ABCD"></base-input>
      </div>
      <!-- <base-button native-type="submit" type="primary" block class=" mt-4" v-on:click="submit()">Submit</base-button> -->
        <button class="btn btn-primary-login mt4" v-on:click="submit()">เข้าใช้งาน</button>
    </form>
  </card>
</div>
</template>
<script>
// Import component
 import Loading from 'vue-loading-overlay';
 import 'vue-loading-overlay/dist/vue-loading.css';
 import { BaseAlert } from "@/components";
 import axios from 'axios';
 import config from '@/config.js';
 import utils from '@/utils';
export default {
  data() {
    return {
      disbox:false,
      logo:'/img/logo.png',
      model: {
        username: '',
        agentid:'',
        password: ''
      },
      isLoading: false,
      fullPage: true,
      isAlert: {open:false,tip:'',msg:''}
    }
  },
  components: {
            Loading,
            BaseAlert
      },
  methods: {
    loadData(){ 
      axios({
          method: 'get',
          url: `${config.apiplayload}/bof/load/${utils.actKeyDomain}`,
          headers: { }
      }).then((response)=>{
        
        if(response.data.ResultData != null){ 
          this.disbox = false;
          this.model.agentid = response.data.ResultData.agentId;
          this.logo = `${config.urlstatic}/${this.model.agentid}/lobby/logo.png?s=500` 
        }else{
          this.disbox = true;
        }
      });
      // axios({
      //     method: 'get',
      //     url: `${config.apiplayload}/load/${utils.actKeyDomain}`,
      //     headers: { }
      // }).then((response)=>{
      //   if(response.data.ResultData.key != null){
      //     let actionKey = response.data.ResultData.action;
      //     this.disbox = false;
      //     this.model.agentid = utils.actKeyDe(actionKey);
      //   }else{
      //     this.disbox = true;
      //   }
      // });
    },
    submit() {
      this.isLoading = true; 
      axios(config.api('post','/login',{
        "user": this.model.username,
        "agentid": this.model.agentid,
        "password": this.model.password,
      },'127.0.0.1')).then((response)=>{
          localStorage.setItem('token',response.data.ResultData);
          this.isLoading = false;
          this.$router.push("/dashboard");
      },(error)=>{
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Warning','warning');
      });
    },
  },
  mounted(){
    this.loadData();
    // if(window.location.href.split("?").length <= 1){
    //   window.location.href = '/?#/login'
    // }
  }
}
</script>
<style media="screen">
  .boxAct{
    padding: 10px;
    border-top: 4px solid; 
    border-radius: 10px;
    margin-top: 25px;
  }
  .box-login{
    position: fixed;
    width: 90%;
    max-width: 540px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  html { 
    background: url(/img/Artboard_bg.png) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .white-content {
      background: transparent;
  }
  .box-login .card-body{
    background: rgba(0, 0, 0, 0.5);
    padding: 60px;
    border-radius: 20px;
  } 
  .form-login-control {
  background-color: #1a196257;
  border: 1px solid #1a196257; 
  font-weight: 300;
  border-radius: 16px;
  padding: 24px 15px;
  color: #FFF;
  width: 100%;
}
  .form-login-control::placeholder{
    color: #FFF !important;
  }
  .form-login-control:focus {
    color: #FFF !important;
    background-color: #1a196257;
    border-color: transparent !important;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, .25);
  }
  .form-login-control:-internal-autofill-selected {
    background-color: rgb(255, 255, 255) !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
  }
  .form-login-control:-webkit-autofill,input:-webkit-autofill:focus {
    background-color: #1a196257 !important;
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }
  .form-login-control:autofill,input:autofill:focus {
    background-color: #1a196257 !important;
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }
  .btn-primary-login,.btn-primary-login:hover{
    width: 100%;
    background: #c21b5e;
  }
</style>
