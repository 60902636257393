<template>
  <base-table-report :data="data" :columns="columns"  thead-classes="text-primary">
  </base-table-report>
</template>
<script>
  import { BaseTableReport } from "@/components";

  /////////////////////
  export default {
    components: {
      BaseTableReport
    },
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      }
    },
    computed: {

    }
  }
</script>
<style>
</style>
